import http from "../http-common";

class Service {
    async getAll(kcSearchString, courses, roles, state, withDetails, statisticsTime, firstDay, lastDay){

        this.abortControllerGetAll = new AbortController();

        const result = await http.get('/users', {
            params: {kcSearchString, courses: JSON.stringify(courses), roles: JSON.stringify(roles), state: JSON.stringify(state), withDetails, statisticsTime, firstDay, lastDay},
            signal: this.abortControllerGetAll.signal
        });

        this.abortControllerGetAll = null;
        return result;
    }

    abortGetAll(){
        if (this.abortControllerGetAll)
            this.abortControllerGetAll.abort();
        this.abortControllerGetAll = null;
    }

    get(userId){
        return http.get(`/users/${userId}`);
    }
    getByUsername(username){
        return http.get(`/users/byUsername/${username}`);
    }

    getTherapyPersons(){
        return http.get('/users/noParticularUser/therapyPersons');
    }

    getSupportPersons(){
        return http.get('/users/noParticularUser/supportPersons');
    }

    /*fetchFromKeycloak(){
        return http.post('/users/fetchFromKeycloak');
    }*/
    set(user){
        return http.put(`/users/${user.id}`, {user});
    }
    delete(userId){
        return http.delete(`/users/${userId}`);
    }
    sendNewPasswordLink(userId){
        return http.put(`/users/sendNewPasswordLink/${userId}`);
    }
    sendVerificationLink(userId){
        return http.put(`/users/sendVerificationLink/${userId}`);
    }
    getAvailableRoles(){
        return http.get('/users/noParticularUser/availableRoles');
    }
    getAvailableRolesLimited(){
        return http.get('/users/noParticularUser/availableRolesLimited');
    }
    getUserRoles(userId){
        return http.get(`/users/${userId}/roles`);
    }
    setUserRoles(userId, roles){
        return http.put(`/users/${userId}/roles`, {roles});
    }
    getLocales(){
        return http.get('/users/noParticularUser/locales');
    }
}

export const UserService = new Service();

