<template>
    <div ref="changeGoals" id="change_goals" class="modal fade" data-bs-backdrop="static" tabindex="-1">
        <div class="modal-dialog modal-m">
            <div class="modal-content modal-padded modal-united">
                <template v-if="state == 'newPhase'">
                    <div class="modal-header">
                        <h4 class="modal-title">{{$t('goals.adaptingGoals')}}</h4>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <p>{{$t('goals.adaptingGoalsExplain')}}</p>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-secondary" @click="saveGoals(true)">{{$t('goals.keepGoals')}}</button>
                        <button type="button" class="btn btn-primary" @click="state='introduction'">{{$t('goals.adaptGoals')}}</button>
                    </div>
                </template>
                <template v-if="state == 'introduction'">
                    <div class="modal-header">
                        <h4 class="modal-title">{{$t('goals.settingGoals')}}</h4>
                    </div>
                    <div class="modal-body">
                        <p style="white-space: pre-wrap">{{currentPhase.intro}}</p>
                        <p style="white-space: pre-wrap">{{currentPhase.advice}}</p>
                        <div class="goals-img">
                            <img src="/img/start.png">
                        </div>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{$t('navigation.cancel')}}</button>
                        <button type="button" class="btn btn-primary" @click="state='choose'">{{$t('navigation.continue')}}</button>
                    </div>
                </template>
                <template v-else-if="state == 'choose'">
                    <div class="modal-header">
                        <h4 class="modal-title">{{$t('dashboard.yourGoals')}}</h4>
                    </div>
                    <div class="modal-body">
                        <p>{{$t('goals.chooseExplain')}}</p>
                        <p>{{$t('goals.iIntendToPractice:')}}</p>
                        <div class="row g-3 align-items-center mb-2">
                            <div class="col-2 text-center"
                                 :aria-label="$t('common.calendar')">
                                <i class="fa-solid fa-braille"></i>
                            </div>
                            <div class="col-4">
                                <select class="form-select goals" v-model="editedGoal.exerciseDaysPerWeek" onchange='this.blur()' required>
                                    <option :value="1">1</option>
                                    <option :value="2" selected>2</option>
                                    <option :value="3">3</option>
                                    <option :value="4">4</option>
                                    <option :value="5">5</option>
                                    <option :value="6">6</option>
                                    <option :value="7">7</option>
                                </select>
                            </div>
                            <div class="col-6">
                                <b>{{$t('goals.daysPerWeek')}}</b>
                            </div>
                        </div>
                        <div class="row g-3 align-items-center mb-4">
                            <div class="col-2 text-center"
                                 :aria-label="$t('common.clock')">
                                <i class="fa-solid fa-clock"></i>
                            </div>
                            <div class="col-4">
                                <input type="number" min="5" max="240" step="5" class="form-control goals" v-model="editedGoal.minutesPerExerciseDay" required>
                            </div>
                            <div class="col-6">
                                <b>{{$t('goals.minutesEachPracticedDay')}}</b>
                            </div>
                        </div>


                        <p class="mb-0"><b>{{$t('goals.timeOfDay')}}</b></p>
                        <p>{{$t('goals.chooseUpToTwo')}}</p>

                        <div class="btn-goals-container" v-if="editedGoal.preferredTimes">
                            <template v-for="time, index in Object.getOwnPropertyNames(timeIcons)">
                                <button type="button" class="btn-goals" @click="togglePreferredTime(time)" :class="editedGoal.preferredTimes.includes(time) ? 'on' : ''"><i class="fa-solid" :class="timeIcons[time]" :aria-label="$t('goals.' + time)"></i>{{$t('goals.' + time)}}</button>
                            </template>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{$t('navigation.cancel')}}</button>
                        <button type="button" class="btn btn-primary" 
                            :class="!editedGoal.exerciseDaysPerWeek || !editedGoal.minutesPerExerciseDay || editedGoal.minutesPerExerciseDay < 1 || editedGoal.preferredTimes.length == 0 ? 'disabled' : ''"
                            @click="checkFrequency">{{$t('navigation.continue')}}</button>

                    </div>
                </template>
                <template v-else-if="state == 'critisizeFrequency'">
                    <div class="modal-header">
                        <h4 class="modal-title">{{$t('goals.critisizeTitle')}}</h4>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-2 text-center"
                                 :aria-label="$t('common.calendar')">
                                <i class="fa-solid fa-braille"></i>
                            </div>
                            <div class="col-10">
                                <p><b>{{$tc('goals.<count>DaysPerWeek', editedGoal.exerciseDaysPerWeek)}}</b></p>
                            </div>
                        </div>
                        <p class="goals-meh" style="white-space: pre-wrap">{{resultFrequency == 'Bad' ? currentPhase.frequencyCommentBad : currentPhase.frequencyCommentOk}}</p>
                        <div class="goals-meh text-center"
                             :aria-label="$t('common.sad')">
                             <!-- Note: no fa-face-smile here because in that case critisizeFrequency is skipped -->
                            <i class="fa-solid fa-face-meh fa-icon-big"></i>
                        </div>

                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-secondary" @click="state='choose'">{{$t('navigation.change')}}</button>
                        <button type="button" class="btn btn-primary" @click="checkMinutes">{{$t('navigation.continue')}}</button>
                    </div>
                </template>
                <template v-else-if="state == 'critisizeMinutes'">
                    <div class="modal-header">
                        <h4 class="modal-title">{{$t('goals.critisizeTitle')}}</h4>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-2 text-center"
                                 :aria-label="$t('common.clock')">
                                <i class="fa-solid fa-clock"></i>
                            </div>
                            <div class="col-10">
                                <p><b>{{$tc('goals.<count>MinutesPerPracticedDay', editedGoal.minutesPerExerciseDay)}}</b></p>
                                <p><b>{{$tc('goals.thatsATotalOf<count>MinutesPerWeek', minutesPerWeek)}}</b></p>
                            </div>
                        </div>
                        <p class="goals-meh" style="white-space: pre-wrap">{{resultMinutes == 'Bad' ? currentPhase.amountCommentBad : resultMinutes == 'Excess' ? currentPhase.amountCommentExcess : currentPhase.amountCommentOk}}</p>
                        <div class="goals-meh text-center"
                             :aria-label="$t('common.sad')">
                             <!-- Note: no fa-face-smile here because in that case critisizeMinutes is skipped -->
                            <i class="fa-solid fa-face-meh fa-icon-big"></i>
                        </div>

                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-secondary" @click="state='choose'">{{$t('navigation.change')}}</button>
                        <button type="button" class="btn btn-primary" @click="checkTime">{{$t('navigation.continue')}}</button>
                    </div>
                </template>
                <template v-else-if="state == 'critisizeTime'">
                    <div class="modal-header">
                        <h4 class="modal-title">{{$t('goals.critisizeTitle')}}</h4>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-2 text-center">
                                <template v-for="time in Object.getOwnPropertyNames(timeIcons)">
                                    <i v-if="editedGoal.preferredTimes.includes(time)" :aria-label="$t('goals.' + time)" :class="'fa-solid ' + timeIcons[time]"></i>
                                    <br v-if="editedGoal.preferredTimes.includes(time)"/>
                                </template>
                            </div>
                            <div class="col-10">
                                <p>
                                    <b v-if="editedGoal.preferredTimes.length == 2">
                                        {{$t('goals.youWantToPracticeTimeOfDays<first>And<second>', {first: $t('goals.' + editedGoal.preferredTimes[0]), second: $t('goals.' + editedGoal.preferredTimes[1])})}}
                                    </b>
                                    <b v-else>
                                        {{$t('goals.youWantToPracticeTimeOfDays<first>', {first: $t('goals.' + editedGoal.preferredTimes[0])})}}
                                    </b>
                                </p>
                            </div>
                        </div>
                        <p class="goals-meh" style="white-space: pre-wrap">{{resultTime == 'Bad' ? currentPhase.timeCommentBad : currentPhase.timeCommentOk}}</p>
                        <div class="goals-meh text-center"
                             :aria-label="$t('common.sad')">
                             <!-- Note: no fa-face-smile here because in that case critisizeTime is skipped -->
                            <i class="fa-solid fa-face-meh fa-icon-big"></i>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-secondary" @click="state='choose'">{{$t('navigation.change')}}</button>
                        <button type="button" class="btn btn-primary" @click="state='summary'">{{$t('navigation.continue')}}</button>
                    </div>
                </template>
                <template v-else-if="state == 'summary'">
                    <div class="modal-header">
                        <h4 class="modal-title">{{$t('goals.critisizeTitle')}}</h4>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-4">
                            <div class="col-2 text-center">
                                <div class="col-2"
                                     :aria-label="$t('common.calendar')">
                                    <i class="fa-solid fa-braille"></i>
                                </div>
                            </div>
                            <div class="col-8">
                                <p><b>{{$tc('goals.<count>DaysPerWeek', editedGoal.exerciseDaysPerWeek)}}</b></p>
                            </div>
                            <div class="col-2">
                                <i v-if="resultFrequency == 'Good'" class="fa-solid fa-face-smile goals-yay"
                                    :aria-label="$t('common.happy')">
                                </i>
                                <i v-else class="fa-solid fa-face-meh goals-meh"
                                    :aria-label="$t('common.sad')">
                                </i>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-2 text-center"
                                 :aria-label="$t('common.clock')">
                                <i class="fa-solid fa-clock"></i>
                            </div>
                            <div class="col-8">
                                <p><b>{{$tc('goals.<count>MinutesPerPracticedDay', editedGoal.minutesPerExerciseDay)}}</b></p>
                                <p><b>{{$tc('goals.thatsATotalOf<count>MinutesPerWeek', minutesPerWeek)}}</b></p>
                            </div>
                            <div class="col-2">
                                <i v-if="resultMinutes == 'Good'" class="fa-solid fa-face-smile goals-yay"
                                    :aria-label="$t('common.happy')">
                                </i>
                                <i v-else class="fa-solid fa-face-meh goals-meh"
                                    :aria-label="$t('common.sad')">
                                </i>
                            </div>
                        </div>

                        <div class="row mb-4">
                            <div class="col-2 text-center">
                                <template v-for="time in Object.getOwnPropertyNames(timeIcons)">
                                    <i v-if="editedGoal.preferredTimes.includes(time)" :aria-label="$t('goals.' + time)" :class="'fa-solid ' + timeIcons[time]"></i>
                                    <br v-if="editedGoal.preferredTimes.includes(time)"/>
                                </template>
                            </div>
                            <div class="col-8">
                                <p>
                                <b v-if="editedGoal.preferredTimes.length == 2">
                                    {{$t('goals.youWantToPracticeTimeOfDays<first>And<second>', {first: $t('goals.' + editedGoal.preferredTimes[0]), second: $t('goals.' + editedGoal.preferredTimes[1])})}}
                                </b>
                                <b v-else>
                                    {{$t('goals.youWantToPracticeTimeOfDays<first>', {first: $t('goals.' + editedGoal.preferredTimes[0])})}}
                                </b>
                                </p>
                            </div>
                            <div class="col-2">
                                <i v-if="resultTime == 'Good'" class="fa-solid fa-face-smile goals-yay"
                                    :aria-label="$t('common.happy')">
                                </i>
                                <i v-else class="fa-solid fa-face-meh goals-meh"
                                    :aria-label="$t('common.sad')">
                                </i>
                            </div>
                        </div>
                        <div class="mb-4" v-if="settings && management">
                            <template v-if="sendReminders">
                                {{$t('goals.currentlyReminders')}}
                            </template>
                            <template v-else>
                                {{$t('goals.currentlyNoReminders')}}
                            </template>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-secondary" @click="state='choose'">{{$t('navigation.change')}}</button>
                        <button type="button" class="btn btn-primary" @click="saveGoals(false)">{{$t('navigation.save')}}</button>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import {GoalService} from "../services/goal-service";
import {Modal} from "bootstrap";
import {reportRequestError} from '../utils/messaging.js';
import {useMessageStore} from '../stores/message-store';
import {useGoalStore} from "../stores/goal-store";
import {SettingsService} from '../services/settings-service';

export default {
    props: ['management'],
    setup() {
        return {
            messageStore: useMessageStore(),
            goalStore: useGoalStore()
        }
    },
    data(){
        return {
            settings: null,
            goal: null,
            state: '',
            resultFrequency: '',
            resultMinutes: '',
            resultTime: '',
            editedGoal: {},
            saveEvenUnchanged: false,
            timeIcons: {
                morning: 'fa-mug-hot',
                noon: 'fa-utensils',
                afternoon: 'fa-umbrella-beach',
                evening: 'fa-cloud-moon'
            },
            timeAssessment: [
                ['morning', 'Good'],
                ['noon', 'Ok'],
                ['afternoon', 'Ok'],
                ['evening', 'Bad']
            ]
        }
    },
    computed: {
        userId() {
            return this.$keycloak.subject;
        },
        minutesPerWeek() {
            return this.editedGoal.minutesPerExerciseDay * this.editedGoal.exerciseDaysPerWeek;
        },
        currentPhase(){
            return this.management.therapy.phases[this.management.therapy.currentPhase];
        },
        // see settings.vue: sendReminders
        sendReminders() {
            const therapy = this.management.effectiveTherapy;
            if (therapy.reminders)
                return !this.settings.preventReminders;
            else
                return this.settings.enforceReminders;
        }
    },
    methods: {
        async show(newPhase){
            try {
                if (!this.goalStore.goal)
                    this.goalStore.goal = (await GoalService.getLatest(this.userId)).data;

                if (this.goalStore.goal) {
                    // prune any illegal values (cleans up outdated storage as list string)
                    this.goalStore.goal.preferredTimes = this.goalStore.goal.preferredTimes.filter(x => x in this.timeIcons);

                    if (newPhase){
                        this.state = 'newPhase';
                        this.saveEvenUnchanged = true;
                    } else
                        this.state = 'choose';
                } else 
                    // very first time
                    this.state = 'introduction';

            } catch (err) {
                console.log("error when goal:", err);
                reportRequestError(err);
            }
            this.changeGoals();
            try {
                this.settings =  (await SettingsService.get(this.userId)).data;
            } catch (err) {
                console.log("error when goal:", err);
                reportRequestError(err);
            }
        },
        changeGoals(){
            if (this.goalStore.goal){
                this.editedGoal = Object.assign({}, this.goalStore.goal);
                this.editedGoal.date = null;
            } else
                this.editedGoal = {preferredTimes: []};

            this.editedGoal.okFromDaysPerWeek = this.currentPhase.okFromDaysPerWeek;
            this.editedGoal.goodFromDaysPerWeek = this.currentPhase.goodFromDaysPerWeek;
            this.editedGoal.okFromMinutes = this.currentPhase.okFromMinutes;
            this.editedGoal.goodFromMinutes = this.currentPhase.goodFromMinutes;
            this.editedGoal.excessFromMinutes = this.currentPhase.excessFromMinutes;

            Modal.getOrCreateInstance(this.$refs.changeGoals).show();
        },
        checkFrequency(){
            if (this.editedGoal.exerciseDaysPerWeek >= this.currentPhase.goodFromDaysPerWeek)
                this.resultFrequency = 'Good';
            else if (this.editedGoal.exerciseDaysPerWeek >= this.currentPhase.okFromDaysPerWeek)
                this.resultFrequency = 'Ok';
            else 
                this.resultFrequency = 'Bad';

            if (this.resultFrequency == 'Good')
                this.checkMinutes();
            else 
                this.state = 'critisizeFrequency';
        },
        checkMinutes(){
            if (this.minutesPerWeek >= this.currentPhase.excessFromMinutes)
                this.resultMinutes = 'Excess';
            else if (this.minutesPerWeek >= this.currentPhase.goodFromMinutes)
                this.resultMinutes = 'Good';
            else if (this.minutesPerWeek >= this.currentPhase.okFromMinutes)
                this.resultMinutes = 'Ok';
            else
                this.resultMinutes = 'Bad';

            if (this.resultMinutes == 'Good')
                this.checkTime();
            else 
                this.state = 'critisizeMinutes';
        },
        checkTime(){
            for (let [time, result] of this.timeAssessment)
                if (this.editedGoal.preferredTimes.includes(time)){
                    this.resultTime = result;
                    break;
                }

            if (this.resultTime == 'Good')
                this.state = 'summary';
            else 
                this.state = 'critisizeTime';
        },
        async saveGoals(silent){
            
            try {
                Modal.getOrCreateInstance(this.$refs.changeGoals).hide();

                const result = (await GoalService.add(this.userId, this.editedGoal, this.saveEvenUnchanged)).data;
                console.log("goal result: ", result);

                if (result.ignored)
                    this.messageStore.inform(this.$t('goals.noChangeIgnored'));
                else {
                    if (!silent)
                        this.messageStore.praise(this.$t('goals.goalsSaved'));
                    
                    this.goalStore.goal = Object.assign({}, this.editedGoal);
                }

                // reset
                this.saveEvenUnchanged = false;

                if (this.$route.meta.dependsOnGoals)
                    window.goalsChanged();
                 
            } catch(err){
                console.log("error updating goal:", err);
                reportRequestError(err);
            } 
        },
        togglePreferredTime(time){
            if (this.editedGoal.preferredTimes.includes(time))
                this.editedGoal.preferredTimes = this.editedGoal.preferredTimes.filter(t => t != time);
            else if (this.editedGoal.preferredTimes.length < 2)
                this.editedGoal.preferredTimes.push(time);
        }
    }
}

</script>

