<template>
    <div ref="modalWarning" class="modal fade" data-bs-backdrop="static" tabindex="-1" v-if="doShow">
        <div class="modal-dialog modal-sm">
            <div class="modal-content" :class="isError ? 'modal-alert-danger' : 'modal-alert-warning'">
                <div class="modal-body">
                    <div class="modal-alert-message">
                        <i v-if="isError || !option2TranslationKey" class="fa-solid fa-triangle-exclamation"></i>
                        <i v-else class="fa-solid fa-circle-question"></i>
                        <i18n-t :keypath="translationKey" tag="p">
                            <template v-slot:url1 v-if="url1">
                                <router-link target="_blank" :to="url1"
                                   style="display:inline-block">
                                    <template v-if="url1TranslationKey">
                                        {{$t(url1TranslationKey)}}
                                    </template>
                                    <template v-else>
                                        {{url1Display}}
                                    </template>
                                </router-link>
                            </template>
                        </i18n-t>
                    </div>
                    <div class="modal-alert-options" v-if="option2TranslationKey">
                        <button type="button" class="btn btn-secondary" @click="option1Function" data-bs-dismiss="modal">{{$t(option1TranslationKey)}}</button>
                        <button type="button" class="btn btn-secondary" @click="option2Function" data-bs-dismiss="modal">{{$t(option2TranslationKey)}}</button>
                    </div>
                    <div class="modal-alert-options" v-else>
                        <div></div>
                        <button type="button" class="btn btn-secondary" @click="option1Function" data-bs-dismiss="modal">{{$t(option1TranslationKey)}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Modal} from "bootstrap";

export default {
    props: ['translationKey', 'url1', 'url1TranslationKey', 'url1Display',
        'option1TranslationKey', 'option1Function', 'option2TranslationKey', 'option2Function',
        'isError'],
    data() {
        return {
            doShow: false
        }
    },
    methods: {
        show(){
            this.doShow = true;
            setTimeout(() => {
                Modal.getOrCreateInstance(this.$refs.modalWarning).show();
            }, 1000);
        }
    }
}
</script>

