import {defineStore} from 'pinia';

const initialState = {
    goal: undefined
};

export const useGoalStore = defineStore('goal', {
    state: () => {
        return initialState;
    }
});


