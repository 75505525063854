import http from '../http-common';

class Service{
    get(userId, deviceId){
        return http.get(`/devices/${userId}/${deviceId}`);
    }

    getAll(userId){
        return http.get(`/devices/${userId}`);
    }

    set(device){
        const userId = device.user;
        const deviceId = device.id;
        device.lastUpdated = new Date(); // just for local usage, will be set on server
        return http.put(`/devices/${userId}/${deviceId}`, {device});
    }

    delete(userId, deviceId){
        // userId is just required for permission check
        return http.delete(`/devices/${userId}/${deviceId}`);
    }
}

export const DeviceService = new Service();

