import http from "../http-common";

class Service {
    get(userId){
        return http.get(`/journals/${userId}`);
    }
    set(journal){
        return http.post('/journals',
            {journal});
    }
    setLastMessageRead(userId, channelId){
        return http.put(`/journals/${userId}/lastMessageRead/${channelId}`);
    }
    setNumberOfMessages(userId, numberOfMessages){
        return http.put(`/journals/${userId}/numberOfMessages`, 
            {numberOfMessages});
    }
}

export const JournalService = new Service();
 
