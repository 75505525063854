import http from "../http-common";

class NotificationService {
    storeSubscription(subscription, deviceId){
        return http.post('/notifications/storeSubscription', 
            {subscription, deviceId});
    }
    checkSubscription(subscription, deviceId){
        return http.put('/notifications/checkSubscription',
            {subscription, deviceId});
    }
    deactivateSubscription(subscription){
        return http.put('/notifications/deactivateSubscription',
            subscription);
    }
    /* 'subscription' is only required for historic subscriptions where deviceId was not stored yet. */
    test(subscription, deviceId){
        return http.put('/notifications/test',
            {subscription, deviceId});
    }
    testSubscribed(){
        return http.put('/notifications/testSubscribed');
    }
    subscribe(subscription, data, vue){
        return http.post('/notifications/subscribe', 
            subscription);
    }
}

export default new NotificationService();

