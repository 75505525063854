import http from '../http-common';

class Service{
    getAll(id){
        return http.get(`/courses/`);
    }

    get(id){
        return http.get(`/courses/${id}`);
    }

    getMine(){
        return http.get('/courses/assignedAsTherapist/mine');
    }

    getUsingTherapyOne(therapyId){
        return http.get(`/courses/usingTherapyOne/${therapyId}`);
    }

    create(course){
        return http.post(`/courses`, {course});
    }

    set(course){
        return http.put(`/courses`, {course});
    }

    setRestricted(course){
        return http.put(`/courses/restricted`, {course});
    }

    delete(id){
        return http.delete(`/courses/${id}`);
    }

    getUsers(id){
        return http.get(`/courses/${id}/users`);
    }

    getChatFellows(){
        return http.get(`/courses/mycourse/chatFellows`);
    }

    getTherapists(id){
        return http.get(`/courses/${id}/therapists`);
    } 

    /* superfluous, as long as therapists may setTreatedCourses 
    addMeToTherapists(id){
        return http.post(`/courses/${id}/therapists`);
    }

    removeMeFromTherapists(id){
        return http.delete(`/courses/${id}/therapists`);
    }*/

    getTreatedCourses(userId){
        return http.get(`/courses/treatedCourses/${userId}`);
    }

    setTreatedCourses(userId, courses){
        return http.put(`/courses/treatedCourses/${userId}`, {courses});
    }
}

export const CourseService = new Service();

